import $ from 'jquery'
import { NoticeManager } from './notice_manager'
import { entrypoints } from '../../entrypoints'

//#
// Process response.
//
// @param {object} data - Response data.
// @param {object} data.update - DOM updates mapping DOM IDs to new inner HTML.
// @param {object} data.replace - DOM replacements mapping DOM IDs to new outer
// HTML.
// @param {string[]} data.remove - DOM IDs to remove.
// @param {string} data.redirect - Redirect location.
// @param {object} data.message - Message.
// @param {string} data.message.status - Status.
// @param {string} data.message.text - Text.
export const processResponse = function (data) {
  let id, klass

  // NOTE: only redirect/reload suported for now.
  const windowToUse = data.top_frame && window.top ? window.top : window

  addClassToBody()

  const prepareHtmlfromResponse = (data) => {
    if (data.css_entrypoints || data.js_entrypoints) {
      entrypoints(data.css_entrypoints, data.js_entrypoints)
    }

    return data.html || data
  }

  if (data.remove != null) {
    for (id of Array.from(data.remove)) {
      $('#' + id).remove()
    }
  }

  if (data.update != null) {
    for (id in data.update) {
      $(getSelectorOrId(id)).html(prepareHtmlfromResponse(data.update[id]))
    }
  }

  if (data.replace != null) {
    for (id in data.replace) {
      $(getSelectorOrId(id)).replaceWith(
        prepareHtmlfromResponse(data.replace[id])
      )
    }
  }

  if (data.redirect != null) {
    // Safari shows a confirmation dialog if we don't reset the trix input state until you chose 'Leave page' once
    $('.trix-editor-container').find('input').remove()
    windowToUse.location.href = data.redirect
  }

  if (data.reload != null) {
    // Safari shows a confirmation dialog if we don't reset the trix input state until you chose 'Leave page' once
    $('.trix-editor-container').find('input').remove()
    windowToUse.location.reload()
  }

  if (data.add_class != null) {
    for (id in data.add_class) {
      klass = data.add_class[id]
      $(getSelectorOrId(id)).addClass(klass)
    }
  }

  if (data.remove_class != null) {
    for (id in data.remove_class) {
      klass = data.remove_class[id]
      $(getSelectorOrId(id)).removeClass(klass)
    }
  }

  if (data.extract_and_replace != null) {
    for (const id in data.extract_and_replace) {
      const selector = id
      const html = $(data.extract_and_replace[id]).find(selector)
      $(selector).replaceWith(html)
      $(selector).changed()
    }
  }

  if (data.slide_up != null) {
    for (let selector of Array.from(data.slide_up)) {
      $(getSelectorOrId(selector)).slideUp(function () {
        return $(this).remove()
      })
    }
  }

  if (data.trigger_event_on_document != null) {
    for (let event of Array.from(data.trigger_event_on_document)) {
      $(document).trigger(event)
    }
  }

  if (data.message != null) {
    noticeManager.show(data.message.status, data.message.text)
  }

  return removeClassFromBody()
}

const noticeManager = new NoticeManager()

const addClassToBody = () => $('body').addClass('performing-action')

const removeClassFromBody = () => $('body').removeClass('performing-action')

// - if given str starts with a '.' or '#', we assume it's complete selector
// - if not, assume it's an id, and add an '#' (for backwards compatibility)
const getSelectorOrId = function (str) {
  if (str.startsWith('.') || str.startsWith('#')) {
    return str
  } else {
    return `#${str}`
  }
}
