export function entrypoints(cssEntrypoints, jsEntrypoints) {
  if (cssEntrypoints) {
    cssEntrypoints.forEach((cssEntrypoint) => {
      if (!document.querySelector(`link[href="${cssEntrypoint}"]`)) {
        let link = document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('href', cssEntrypoint)
        document.head.appendChild(link)
      }
    })
  }

  if (jsEntrypoints) {
    Promise.all(
      jsEntrypoints.map((jsEntrypoint) => {
        return new Promise((resolve, _reject) => {
          if (document.querySelector(`script[src="${jsEntrypoint}"]`)) {
            resolve()
            return
          }
          let script = document.createElement('script')
          script.setAttribute('src', jsEntrypoint)
          script.setAttribute('type', 'module') // Otherwise imports don't work
          script.addEventListener('load', resolve)
          document.head.appendChild(script)
        })
      })
    )
  }
}
