import $ from 'jquery'

export class NoticeManager {
  constructor() {
    $(() => {
      let $notice
      if (($notice = $('#notice-container .notice')).length) {
        return this._manageNotice($notice)
      }
    })
  }

  show(type, message) {
    const $notice = $('<div></div>')
    $notice.addClass(`notice notice--${type}`)
    $notice.html(message)

    $('#notice-container').html('').append($notice)

    return this._manageNotice($('#notice-container .notice'))
  }

  showSuccess(message) {
    return this.show('success', message)
  }

  showError(message) {
    return this.show('error', message)
  }

  _hideNotice($notice) {
    return $notice.remove()
  }

  _manageNotice($notice) {
    $notice.click(() => this._hideNotice($notice))
    // Do not hide in test env to avoid timing issues.
    if ($('body[data-permanotices=1]').length === 0) {
      return setTimeout(() => this._hideNotice($notice), 10000)
    }
  }
}
